exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/about-template.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contact-template.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/index-template.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../../../src/templates/tags-template.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

